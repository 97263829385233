






















































import { Component, Vue } from 'vue-property-decorator';
import Product, { NewProduct } from '@/data/models/product';
import { formatPrice, capitalizeFirstLetter } from '@/helpers/format-string';
import Button from '@/components/commons/Button.vue';
import { Cart } from '@/data/models/types/order-types';
import { ADD_TO_CART, REMOVE_FROM_CART } from '@/store/mutation-types';
import { BToast } from 'bootstrap-vue';

@Component({
   components: { Button, BToast }
})
export default class SelectProducts extends Vue {
   products: Product[] = [];

   mounted() {
      this.$service.products.getAllProducts().then(products => {
         this.products = [...products, new NewProduct()];
      });
   }

   get selectedProducts(): Cart {
      return this.$store.state.cart;
   }

   formatPrice(price: number): string {
      return `${formatPrice(price)} / ${this.$t('common.piece')}`;
   }

   capitalizeFirstLetter(string: string): string {
      return capitalizeFirstLetter(string);
   }

   getProductQuantity(product: Product): number {
      return this.selectedProducts[product.id]?.total;
   }

   select(product: Product) {
      if (product.id === 'newProduct') {
          this.openCrisp()
         return;
      }

      this.$store.commit(ADD_TO_CART, product);

      this.$bvToast.toast(this.$t('home.productsAdded').toString(), {
         title: this.$t('home.super').toString(),
         autoHideDelay: 2000,
         noCloseButton: true,
         toaster: 'b-toaster-bottom-right',
         variant: 'primary'
      });
   }

   deselect(product: Product) {
      this.$store.commit(REMOVE_FROM_CART, product);
   }
   openCrisp() {
      (window as any).$crisp.push(['do', 'chat:open']);
   }
}
