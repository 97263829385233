















import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Ratings extends Vue {
   ratings: any[] = [];

   mounted() {
      this.ratings = [
         {
            name: 'John Doe',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            rate: 3
         },
         {
            name: 'John Doe',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            rate: 2
         },
         {
            name: 'John Doe',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            rate: 3
         }
      ];
   }
}
