



















import { Component, Vue } from 'vue-property-decorator';
import RelayPoints from '@/components/home/RelayPoints.vue';
import SelectProducts from '@/components/home/SelectProducts.vue';
import CompanyInfo from '@/components/home/CompanyInfo.vue';
import CompanyConcept from '@/components/home/CompanyConcept.vue';
import Ratings from '@/components/home/Ratings.vue';
import { MetaInfo } from 'vue-meta';

@Component({
  metaInfo(): MetaInfo {
    return {
      title: this.$t('meta.home.title').toString(),
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.$t('meta.home.description').toString()
        }
      ]
    };
  },
  components: { CompanyConcept, CompanyInfo, SelectProducts, RelayPoints, Ratings }
})
export default class Home extends Vue {
}
