

































import { Component, Prop, Vue } from 'vue-property-decorator';
import Relay from '@/data/models/relay';
import DateUtils from '@/helpers/date-utils';
import Button from '@/components/commons/Button.vue';
import { formatStringPlanning } from '@/helpers/format-string';
import { SELECT_RELAY } from '@/store/mutation-types';
import { trackEvent, EventType } from '@/helpers/analytics';

@Component({
   components: { Button }
})
export default class RelayDetail extends Vue {
   @Prop({ required: true })
   relay: Relay;

   @Prop({ default: false })
   isAskingRelay: boolean;

   @Prop({ required: true })
   opened: boolean;

   isLoading: boolean = false;

   dayLocalized(value: number): string {
      return DateUtils.intToDay(value);
   }

   displayDayPlanning(daySchedule): string {
      return formatStringPlanning(daySchedule);
   }

   selectRelay(): void {
      this.$store.commit(SELECT_RELAY, this.relay);
      this.close();

      trackEvent(EventType.HOME_SELECT_RELAY, { relay_name: this.relay.name, relay_id: this.relay.id });
   }

   sendMail(): Promise<void> {
     this.isLoading = true;

     return this.$service.relays.askRelay(this.relay.place)
       .then(() => {
         this.close();

         this.$bvToast.toast(this.$t('home.askedDescription').toString(), {
           title: this.$t('home.askedTitle').toString(),
           autoHideDelay: 2000,
           noCloseButton: true,
           toaster: 'b-toaster-bottom-right',
           variant: "success"
         })
       }).finally(() => this.isLoading = false);
   }

   close() {
      this.$emit('closeDetail');
   }
}
