





















import { Component, Vue } from 'vue-property-decorator';
import Relay from '@/data/models/relay';
import GoogleMapsApiLoader from 'google-maps-api-loader';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import config from '@/config/index';
import Map from '@/components/home/Map.vue';
import { trackEvent, EventType } from '@/helpers/analytics';
import Place from '@/data/models/place';

@Component({
   components: { Map, VueGoogleAutocomplete }
})
export default class RelayPoints extends Vue {
   relays: Relay[] = [];
   position: [number, number] = null;
   placeRequested: { city: string; zipCode: number } = { city: null, zipCode: null };

   isLoading: boolean = false;

   beforeMount() {
      this.isLoading = true;
      GoogleMapsApiLoader({ libraries: ['places'], apiKey: config.googleApiKey }).then(() => (this.isLoading = false));
   }

   mounted() {
      this.$service.relays.getAllRelays().then(relays => (this.relays = relays));
   }

   get requestRelay(): Relay {
      const cityHasRelay = this.relays.some(relay => relay?.place?.city?.toLowerCase() === this.placeRequested?.city?.toLowerCase());

      if (cityHasRelay) {
         return null;
      }

      return Relay.map({ place: Place.map(this.placeRequested) });
   }

   getPlace(data): void {
      trackEvent(EventType.HOME_SELECT_ADDRESS, { place: data });
      this.position = [data.latitude, data.longitude];
      this.placeRequested = { city: data.locality, zipCode: data.postal_code };
   }
}
