































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import 'vue2-leaflet';
import { LMap, LMarker, LTileLayer, LIcon, LControlZoom, LTooltip } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import Relay from '@/data/models/relay';
import RelayDetail from '@/components/home/RelayDetail.vue';

//the maker icons are missing, the issue lies in a problem with webpack
import { Icon } from 'leaflet';
import MapMyPosition from '@/components/home/MapMyPosition.vue';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

@Component({
   components: { MapMyPosition, RelayDetail, LMap, LTileLayer, LMarker, LIcon, LControlZoom, LTooltip }
})
export default class Map extends Vue {
   $refs!: {
      map: LMap;
   };

   @Prop({ required: true })
   relays: Relay[];

   @Prop({ default: null })
   position: [];

   @Prop({ default: null })
   requestRelay: Relay;

   markers: { relay: Relay; selected: boolean }[] = [];
   zoom: number = 11;

   selectedRelay: Relay = null;
   detailIsOpened: boolean = false;
   isAskingRelay: boolean = false;

   mounted() {
      try {
         this.$refs.map.mapObject.addInitHook('addHandler', 'gestureHandling', GestureHandling);
      } catch (e) {
         // Do nothing
      }
   }

   @Watch('relays', { immediate: true })
   onRelaysChanged() {
      this.relays.map(relay => {
         this.markers.push({ relay: relay, selected: false });
      });
   }

   get center() {
      return this.position ?? [47.31322, -1.319482];
   }

   get displayedMarkers() {
      return this.markers
         .filter(marker => marker.relay.place.lat != null && marker.relay.place.long != null)
         .map(marker => {
            return {
               relay: marker.relay,
               selected: marker.selected
            };
         });
   }

   openRelayDetail(relay, askingRelay: boolean) {
      this.selectedRelay = relay;
      this.detailIsOpened = true;
      this.isAskingRelay = askingRelay;
   }

   getIconPath(relay: Relay) {
      if (relay.isEasyLaverie) {
         return require('../../assets/images/pin_easy_laverie.svg');
      }
      return require('../../assets/images/pin.svg');
   }
}
