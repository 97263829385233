


























import { Component, Vue } from 'vue-property-decorator';
import Lottie from '@/components/commons/Lottie.vue';

@Component({
   components: {
      lottie: Lottie
   }
})
export default class CompanyInfo extends Vue {}
