





















import { Component, Vue } from 'vue-property-decorator';
import CovidAlert from './CovidAlert.vue';
import Lottie from '@/components/commons/Lottie.vue';

@Component({ components: { CovidAlert, lottie: Lottie } })
export default class CompanyConcept extends Vue {
   isCovidAlertOpen: boolean = false;

   get steps() {
      return [
         {
            id: 1,
            image: require('@/assets/images/home/illustration_home_laundry_basket.svg'),
            text: this.$t('home.steps.1'),
            animation: 'laundry_basket'
         },
         {
            id: 2,
            image: require('@/assets/images/home/illustration_home_map.svg'),
            text: this.$t('home.steps.2'),
            animation: 'map'
         },
         {
            id: 3,
            image: require('@/assets/images/home/illustration_home_phone.svg'),
            text: this.$t('home.steps.3'),
            animation: 'drop'
         },
         {
            id: 4,
            image: require('@/assets/images/home/illustration_home_phone.svg'),
            text: this.$t('home.steps.4'),
            animation: 'phone'
         }
      ];
   }
}
